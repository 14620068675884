import Vue from 'vue'
import Vuex from 'vuex'
// 登录的所有状态
import userInfo from './userInfo.js'

Vue.use(Vuex)

export default new Vuex.Store({
  // 公共的状态
  state: {},
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {},
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {},
  // 嵌套模块
  modules: {
    userInfo
  }
})
