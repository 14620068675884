<template>
<!-- <div id="app" :class="{ AppMinWidth: isMinWidth }">
    <div :style="minHeight" id="contentApp"> -->
  <div id="app">
    <div  id="contentApp">
      <ConfigProvider :locale="locale">
        <router-view />
      </ConfigProvider>
    </div>
  </div>
</template>

<script>
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  // 可用组件的哈希表
  components: { ConfigProvider },
  // 数据对象
  data() {
    return {
      appKey: 1,
      minHeight: {},
      isMinWidth: false,
      locale: zhCN,
    };
  },
  // 事件处理器
  methods: {
    FunminHeight() {
      const obj = {
        minHeight: document.documentElement.clientHeight - 120 + "px",
      };
      this.minHeight = obj;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.path != "/") {
      this.isMinWidth = true;
    }
    // 判断还原globalData的数据
    if (localStorage.getItem("userInfo")) {
      this.$store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.FunminHeight();
    const _this = this;
    window.onresize = function () {
      _this.FunminHeight();
    };

    // 检测断网
    // window.addEventListener("offline", () => {
    //   this.$message.error('网络异常，请确认联网后再进行操作');
    // });
    // window.addEventListener("online", () => {
    //   this.$message.success('网络连接正常');
    // })

    // 禁用页面打开控制台
    document.onkeydown = () => {
      //禁用F12
      if (window.event && window.event.keyCode == 123) {
        return false;
      //禁用ctrl+shift+i,
      } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
        return false;
      //屏蔽Shift+F10
      } else if (window.event.shiftKey && window.event.keyCode == 121) {
        return false;
      }
    };
    // 禁止鼠标右击
    document.oncontextmenu = function () {
      event.returnValue = false;
    };
  },
  watch: {
    $route() {
      if (this.$route.path != "/") {
        this.isMinWidth = true;
      } else {
        this.isMinWidth = false;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
.AppMinWidth {
  // min-width: 750px;
}
</style>
