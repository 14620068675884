import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less'
import 'assets/css/reset.css'
import 'assets/css/style.less'
import ajax from './ajax.js'
import Global from 'assets/js/Global.js'
import upload from '@/utils/upload.js'
import uoloadImg from '@/utils/uoloadImg.js'
import upload_video from '@/utils/upload_video.js'

import {
  Button,
  message,
  Input,
  Radio,
  Checkbox,
  Affix,
  Collapse,
  Icon,
  Upload,
  Modal,
  Table,
  Alert,
  Spin,
  Empty
} from 'ant-design-vue'

// 延时改为2秒，且最多只有一个
message.config({
  duration: 2,
  maxCount: 1
})

Vue.use(Button)
  .use(Input)
  .use(Checkbox)
  .use(Affix)
  .use(Radio)
  .use(message)
  .use(Collapse)
  .use(Upload)
  .use(Icon)
  .use(Modal)
  .use(Table)
  .use(Alert)
  .use(Spin)
  .use(Empty)

Vue.config.productionTip = false
Vue.prototype.$ajax = ajax
Vue.prototype.$Global = Global

Vue.prototype.$upload = upload
Vue.prototype.$uoloadImg = uoloadImg
Vue.prototype.$upload_video = upload_video
Vue.prototype.$message = message
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
