import TcVod from 'vod-js-sdk-v6'
import ajax from '../ajax.js'

// 获取临时权限
function getToKen(){
  return ajax({
    url: '/vod/signature',
    method: 'post'
  }).then(res=>{
    if(res.code == 200 && res.success){
      return res.data
    }
  })
}

/*
  文件上传
  调用方式：
    upload_video({
      file: fileObject,
      progress: (e)=>{},
      success: (e)=>{}
    })
*/
export default function upload_video({
  file,
  progress,
  success
}){
  const tcVod = new TcVod({
    getSignature: getToKen // 前文中所述的获取上传签名的函数
  })
  const uploader = tcVod.upload({
    mediaFile: file,
    // coverFile: coverFile,
  })
  // 视频上传完成时
  uploader.on('media_upload', function(info) {
    // uploaderInfo.isVideoUploadSuccess = true;
    success(info)
  })
  // 视频上传进度
  uploader.on('media_progress', function(info) {
    // uploaderInfo.progress = info.percent;
    progress(info)
  })
  // 封面上传完成时
  uploader.on('cover_upload', function(info) {
    // uploaderInfo.isCoverUploadSuccess = true;
  })
  // 封面上传进度
  uploader.on('cover_progress', function(info) {
    // uploaderInfo.coverProgress = info.percent;
  })
}