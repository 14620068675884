export default
{
  // 图片错误替换
  errorimg () {
    return (
      'this.src="' +
      require('@/assets/image/defaultLevel.png') +
      '";this.onerror=null'
    )
  }
}
