import axios from 'axios'
import config from './utils/config.js'
import store from './store'

// 是否取消重复的请求
let cancelDuplicated = false
// 存储每个请求的标识和取消的函数
let pendingAjax = new Map()

axios.defaults.timeout = 300000
// axios.defaults.headers.post['userId'] = 1
axios.defaults.baseURL = `${window.location.origin}` + '/hxclass-management' + `/${config.baseUrl}`
// axios.defaults.baseURL = 'https://stable.manage.hxclass.cn/hxclass-management/' + `${config.baseUrl}`
// axios.defaults.baseURL = 'https://alpha.manage.hxclass.cn/hxclass-management/' + `${config.baseUrl}`

// 检测类型
const isFunction = (obj) => { return typeof obj === 'function' }

// 生成重复标识的方式
let duplicatedKeyFn = isFunction(duplicatedKeyFn) ? duplicatedKeyFn : (config) => `${config.method}${config.url}${JSON.stringify(config.params)}`

// 请求
const ajax = (options) => {
  options.method = options.method || 'get'
  if (options.method.toLocaleLowerCase() === 'get') {
    // get请求
    return new Promise((resolve, reject) => {
      axios.get(options.url, { params: options.params || {} }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'post') {
    // post请求
    let params = options.params || {}
    if (options.headers && options.headers.length > 0) {
      const header = options.headers.filter(item => item.type == 'file')
      const type = header[0].type
      if (type == 'file') { // post请求类型为file请求
        let fd = new FormData()
        for (var key in params) {
          fd.append(key, params[key])
        }
        params = fd
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(options.url, (params)).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'put') {
    // put请求
    let params = options.params || {}
    if (options.headers && options.headers.length > 0) {
      const header = options.headers.filter(item => item.type == 'file')
      const type = header[0].type
      if (type == 'file') { // put请求类型为file请求
        let fd = new FormData()
        for (var key in params) {
          fd.append(key, params[key])
        }
        params = fd
      }
    }
    return new Promise((resolve, reject) => {
      axios.put(options.url, (params)).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  } else if (options.method.toLocaleLowerCase() === 'delete') {
    // delete请求
    return new Promise((resolve, reject) => {
      axios.delete(options.url, (options.params || {})).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

// 格式化参数
const formatParam = (params) => {
  const p = {}
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== 'undefined') {
      p[`${key}`] = params[key]
    }
  }
  return p
}

const removePendingAjax = (request) => {
  if (!cancelDuplicated) return
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  // 如果pendingAjax中存在当前请求, 取消当前请求并将其删除
  if (duplicatedKey && pendingAjax.has(duplicatedKey)) {
    // const cancel = pendingAjax.get(duplicatedKey);
    cancel(duplicatedKey)
    pendingAjax.delete(duplicatedKey);
  }
}

const addPendingAjax = (request) => {
  if (!cancelDuplicated) return
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  request.cancelToken = request.cancelToken || new axios.CancelToken((cancel) => {
    // 如果pendingAjax中不存在当前请求，添加进去
    if (duplicatedKey && !pendingAjax.has(duplicatedKey)) {
      pendingAjax.set(duplicatedKey, cancel);
    }
  });
}

const removePending = (request) => {
  const veryConfig = request.veryConfig || {};
  const duplicatedKey = JSON.stringify({
    duplicatedKey: veryConfig.duplicatedKey || duplicatedKeyFn(request),
    type: 'REQUEST_TYPE.DUPLICATED_REQUEST',
  });
  pendingAjax.delete(duplicatedKey);
}

// 请求拦截器 - 请求前数据处理
axios.interceptors.request.use(request => {
  // 在请求开始之前检查先前的请求
  removePendingAjax(request)
  // 将当前请求添加到pendingAjax
  addPendingAjax(request)

  // get请求添加时间戳，防止IE下缓存
  if (request.method.toLocaleLowerCase() === 'get') {
    request.params = formatParam(request.params)
    request.params._t = new Date().getTime()
  }
  // 增加请求头
  if (store.state.userInfo) {
    request.headers['Authorization'] = store.state.userInfo.token
  }

  return request
}, error => {
  return Promise.reject(error)
})

// 响应拦截器 - 返回数据处理
axios.interceptors.response.use(response => {
  // 请求完成之后去掉该请求记录
  removePending(response.config)

  // 获取登录完成后返回的token
  if (response.headers.token) {
    let obj = {
      token: response.headers.token
    }
    store.commit('updateUserInfo', obj)
  }

  if (response.state && (response.state === '405' || response.state === '406')) {
  } else {
    return response.data
  }
}, error => {
  // 类型是否为重复请求
  let isDuplicatedType;
  try {
    const errorType = (JSON.parse(error.message) || {}).type
    isDuplicatedType = errorType === 'REQUEST_TYPE.DUPLICATED_REQUEST'
  } catch (error) {
    isDuplicatedType = false
  }
  if (isDuplicatedType) return false
})

export default ajax
