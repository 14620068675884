const COS = require('cos-js-sdk-v5');
import ajax from '../ajax.js'

// 获取临时权限
function getToKen(name){
  return ajax({
    url: '/cos/get-credential',
    params: {
      fileName: name
    }
  }).then(res=>{
    if(res.code == 200 && res.success){
      return Promise.resolve(res)
    }
  })
}

/*
  文件上传
  调用方式：
    upload({
      file: fileObject,
      progress: (e)=>{},
      success: (e)=>{}
    })
*/
export default function upload({
  file,
  progress,
  success
}){
  let config = new Object()
  getToKen('recode_video_mp4.mp4').then(res=>{
    config = res.data
    const cos = new COS({
      getAuthorization: (options, callback)=>{
        callback({
          TmpSecretId: config.credentials.tmpSecretId,
          TmpSecretKey: config.credentials.tmpSecretKey,
          SecurityToken: config.credentials.sessionToken,
          StartTime: config.startTime,
          ExpiredTime: config.expiredTime,
        });
      }
    });
    cos.putObject({
      Bucket: config.bucket,
      Region: config.region,
      Key: config.key,
      StorageClass: 'STANDARD',
      SliceSize: 1024 * 1024 * 5,
      Body: file, // 上传文件对象
      onProgress: function(progressData) {
        // 上传进度
        progress(progressData)
      }
    }, function(err, data) {
      // 上传结果
      if(data.statusCode == 200){
        success(config.host + config.key)
      }else{
        console.log(err || data)
      }
    })
  })
}