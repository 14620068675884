let obj = new Object()

if(process.env.NODE_ENV == 'development'){
  // 开发环境
  obj = {
    // 代理前缀
    baseUrl: 'api',
    // 请求地址
    target: 'http://192.168.0.26:8083/hxclass-management/',
    // 图片地址
    pictureUrl: 'https:///.cdpservice.com',
  }
}else{ 
  // 生产环境
  obj = {
    // 代理前缀
    baseUrl: '',
    // 请求地址
    target: '',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
  }
}

export default obj