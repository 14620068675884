export default{
  // 公共的状态
  state: {
    isLogin: false, // 当前是否登录
    token:'',
    isDisable: true,
    isPassed: false,
  },
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {
    // 更新数据
    updateUserInfo(state, props){
      if(typeof(props) == 'object'){
        for(let key in props){
          state[key] = props[key]
        }
        localStorage.setItem('userInfo', JSON.stringify(state))
      }else{
        console.error('updateUserInfo()类型错误，期望为object，实际为'+typeof(props))
      }
    },

    // 清除数据，包括
    clearUserInfo(state){
      for(let key in state){
        delete state[key]
      }
      state.isLogin = false
      localStorage.removeItem('userInfo')
    }
  },
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {
    // 异步更新数据
    actionsUpdateUserInfo (context, props) {
      context.commit('updateUserInfo', props)
    }
  },
  // 嵌套模块
  modules: {}
}
