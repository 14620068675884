import Vue from 'vue'
import $store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/loginPage.vue')
  },
  {
    path: '/Mobile404',
    name: 'Mobile404',
    component: () => import('@/views/Mobile404.vue')
  },
  {
    path:'/ExamView',
    component: () => import('@/views/ExamView.vue'),
    redirect:'/ExamView/ExamHome',
    children:[
      {
        path:'/ExamView/ExamHome',
        component: () => import('@/views/ExamHome.vue')
      },
      {
        path:'/ExamView/ExamInfo',
        component: () => import('@/views/ExamInfo.vue')
      },
      {
        path:'/ExamView/ExamScore',
        component: () => import('@/views/ExamScore.vue')
      },
      {
        path:'/ExamView/ExamScoreNoPublished',
        component: () => import('@/views/ExamScoreNoPublished.vue')
      }
    ]
  },
  {
    path:'/ExamView/ExamSystem',
    beforeEnter: ((to, from, next) => {
      // const launchFlag = localStorage.getItem('userInfo')
      // let a = JSON.parse(localStorage.getItem('userInfo').isSubmit)
      let isSubmit = JSON.parse(localStorage.getItem('userInfo')).isSubmit
      console.log(isSubmit)
      // 判断是否已存在，如果存在直接去首页
      if (isSubmit) {
        next('/')
      } else {
        next()
      }
    }),
    component: () => import('@/views/ExamSystem.vue')
  },
  {
    path: '/ExamView/ExamFace',  // 活体比对
    name: 'ExamFace',
    component: () => import('@/views/ExamFace.vue')
  },
  {
    path: '/ExamView/ExamFaceImage',  // 照片比对
    name: 'ExamFaceImage',
    component: () => import('@/views/ExamFaceImage.vue')
  },
  {
    path: '/video_face_detector',
    name: 'Video人脸跟踪',
    component: () => import('@/views/VideoFaceDetector.vue')
  },
  // 错误页面error,所有找不到的 * 匹配错误页面都放在最下面
  {
    path: '*',
    component: () => import('@/views/error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 如果页面路径不是404，则进入判断
  if(!to.params.pathMatch){
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
    const isLogin = $store.state.userInfo.isLogin
    var str = navigator.userAgent
    var ipad = str.match(/(iPad).*OS\s([\d_]+)/)
    var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/)
    var isAndroid = str.match(/(Android)\s+([\d.]+)/)
    var windowWidth = window.innerWidth;
    // 移动端访问直接限制
    if((isAndroid && windowWidth < 767) || isIphone){
      if (to.path === '/Mobile404') {
        next()
      } else {
        next('/Mobile404') // 否则全部重定向到登录页
      }
    } else if (isLogin || userInfo.isLogin || to.fullPath === '/') {
      next()
    } else {
      next({
        path: '/'
      })
    }
  }else{
    next()
  }
})
export default router
